import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    datoCmsHome: { seoMetaTags, title, bannerLink, bannerVideo, modularBlocks },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerLink {
          text
          page {
            ...LinkFragment
          }
        }
        bannerVideo {
          format
          url
          video {
            thumbnailUrl
            mp4Url
          }
        }
        modularBlocks {
          ...CallOutCardsModularBlockFragment
          ...ContentCardsModularBlockFragment
          ...FeaturedAwardsModularBlockFragment
          ...FeaturedImagesModularBlockFragment
          ...FeaturedProductsModularBlockFragment
          ...ImageSliderModularBlockFragment
          ...ImageUspsModularBlockFragment
          ...IntroContentModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout seo={seoMetaTags} isHome={true}>
      <main>
        <Banner heading={title} link={bannerLink} video={bannerVideo} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
